/* eslint-disable max-len */
import { Map } from "immutable";
import * as React from "react";
import { connect } from "react-redux";

import { Home } from "./App/components/09-views/02-pages/Home";
import { DispatchFunc } from "./store/ActionTypes";
import { ReduxState, RemoteErrors } from "./store/ReduxState";
import { remoteClearError } from "./store/RemoteActions";
import { RemoteErrorType, RemoteScope } from "./store/RemoteTypes";
import { RouteComponentProps } from "react-router-dom";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { Blockquote } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/05-atoms";
import { Themes } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/theme";
import { PageWrapper } from "./App/components/09-views/01-sections/PageWrapper";
import { Setup } from "./App/components/09-views/02-pages/Setup";
import { getCurrentEnv } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/utils/WhichEnv";
import { NoMatchingRoute } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/09-views/02-pages";

interface State {
    asPage: boolean;
}

/**
 *
 */
interface DispatchProps {
    handleRemoteError: (scope: RemoteScope, error: RemoteErrors) => void;
}

/**
 *
 */
interface StateProps {
    remoteErrors: Map<RemoteScope, RemoteErrors>;
    theme: Mary.theme.Themes;
    routeProps?: string;
}

interface OwnProps {
    apiEndPoint?: string;
    setTheme: (to: Mary.theme.Themes) => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

const handleRemoteErrorModal = (id: string, onClose: () => void, error: RemoteErrors) => {
    const modal = (
        <Mary.views.modals.ModalNotification
            id={id}
            title={error.type === RemoteErrorType.VALIDATION
                ? Mary.utils.getText("app.notification", "Notification")
                : Mary.utils.getText("app.warning", "Warning")}
            onCloseModal={onClose}
            theme={{
                paletteState: error.type !== RemoteErrorType.VALIDATION
                    ? error.type === RemoteErrorType.UNKNOWN_ERROR
                        || error.type === RemoteErrorType.DEFAULT
                        ? Mary.theme.ThemePaletteState.DANGER : Mary.theme.ThemePaletteState.WARNING
                    : undefined,
                palette: error.type === RemoteErrorType.VALIDATION ? Mary.theme.ThemePalette.CONTRAST_TERTIARY
                    : undefined,
            }}
        >
            <React.Fragment>
                <Blockquote
                    style={{
                        maxHeight: "200px",
                        overflowY: "scroll",
                        wordWrap: "break-word",
                        whiteSpace: "pre-line",
                    }}
                >
                    <Mary.organisms.RTE>
                        <h5>
                            {error.type !== RemoteErrorType.UNKNOWN_ERROR ?
                                Mary.utils.getText("app.remote-error", "Claude can't continue with your request.") :
                                Mary.utils.getText("app.fatal-remote-error", "Even Claude doesn't know what went wrong here!")
                            }
                        </h5>
                        {error.message}
                    </Mary.organisms.RTE>
                </Blockquote>
            </React.Fragment>
        </Mary.views.modals.ModalNotification>
    );
    // eslint-disable-next-line react/display-name
    return () => modal;
};

const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    handleRemoteError: (scope, error) => dispatch(
        Mary.utils.modalShow("handleremoteerror", handleRemoteErrorModal("handleremoteerror", () => {
            dispatch(Mary.utils.modalClose("handleremoteerror"));
            dispatch(remoteClearError(scope));
        }, error))),
});

const mapStateToProps = (s: ReduxState, p?: RouteComponentProps): StateProps => ({
    remoteErrors: s.prop("remoteErrors"),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    theme: s.prop("currentTheme"),
    routeProps: p?.location?.pathname,
});

class AppComp
    extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = { asPage : true };

        this.checkRemoteError = this.checkRemoteError.bind(this);
        this.inIframe = this.inIframe.bind(this);
        this.inSetup = this.inSetup.bind(this);
        this.renderPage = this.renderPage.bind(this);
    }

    public componentDidMount() {
        this.checkRemoteError();
        const pathname = this.props.routeProps?.substring(1).split("/")?.[0];
        if(pathname) {
            if (this.inSetup()) {
                this.props.setTheme(Themes.CLAUDE);
            } else {
                Object.values(Mary.theme.Themes).map(v => {
                    if(pathname === v) {
                        this.props.setTheme(v);
                    }
                });
            }
        }

        if(this.inIframe()) {
            this.setState({
                asPage: false,
            });
        }
    }

    public componentDidUpdate() {
        this.checkRemoteError();
    }

    /**
     *
     */
    public render() {
        return this.renderPage();
    }

    private inIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    };

    private checkRemoteError = () => {
        const errorEntry = this.props.remoteErrors.findEntry((val) => !!val);
        if (!!errorEntry) {
            const [scope, reason] = errorEntry;
            this.props.handleRemoteError(scope, reason);
        }
    };

    private inSetup = () => {
        const pathname = this.props.routeProps?.substring(1).split("/")?.[0];
        return pathname === "setup";
    };

    private renderPage = () => {
        const home = <Home asPage={this.state.asPage} routeProps={this.props.routeProps?.substring(1).split("/")?.[1]} />;
        if (!this.state.asPage) {
            return home;
        } else if (this.inSetup() && getCurrentEnv() === "local") {
            return <PageWrapper><Setup/></PageWrapper>;
        } else if (this.inSetup() && getCurrentEnv() !== "local") {
            return <PageWrapper><NoMatchingRoute /></PageWrapper>;
        } else {
            return <PageWrapper>{home}</PageWrapper>;
        }
    };
}


/**
 *
 */
const AppWrapper: React.FunctionComponent<OwnProps> = props => (<AppComp {...props as Props} />);

/**
 *
 */
export const App = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppWrapper);
