/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable max-len */
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { ValidationStatus } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/07-organisms/Form/_Types";
import { Field, FieldType } from "../../store/Init/Types";
import { FormMaxLeaseLoanField, MaxLeaseLoanValidations } from "../../store/MaxLeaseLoan/Types";

const setValidationPerField = (props: {
    validations: MaxLeaseLoanValidations;
    field: {
        value: Field["value"];
        type: Field["fieldType"];
        label: Field["title"];
    };
    fieldId: string;
}) => {
    const { field, validations, fieldId } = props;
    if(field.type === FieldType.BOOLEAN) {
        validations[fieldId] = {
            status: field.value === true || field.value === false
                ? ValidationStatus.SUCCESS : ValidationStatus.ERROR,
            message: field.value === true || field.value === false
                ? Mary.utils.getText("app.validation.has-value-message", "", {label: field.label}) : "",
            title: field.label,
        };
    } else if (field.type === FieldType.SELECT) {
        validations[fieldId] =  {
            status: field.value ? ValidationStatus.SUCCESS : ValidationStatus.ERROR,
            message: field.value ?
                Mary.utils.getText(
                    "app.validation.has-value-message", "",
                    {label: field.label})
                : Mary.utils.getText("app.validation.has-value-error-message", "", {label: field.label}),
            title: field.label,
        };
    } else if (field.type === FieldType.CURRENCY) {
        validations[fieldId] =  {
            status: (field.value !== undefined && field.value !== "")
                ? ValidationStatus.SUCCESS : ValidationStatus.ERROR,
            message: (field.value !== undefined && field.value !== "")
                ? Mary.utils.getText("app.validation.has-value-message", "", {label: field.label})
                : Mary.utils.getText("app.validation.has-value-error-message", "", {label: field.label}),
            title: field.label,
        };
    }
};

export const validateFieldForChange =
    (
        fields: FormMaxLeaseLoanField[],
        validations: MaxLeaseLoanValidations,
        fieldId: string,
        field: {
            value: Field["value"];
            type: Field["fieldType"];
            label: Field["title"];
        },
        hasPrevValueFromAPI: boolean,
    ): MaxLeaseLoanValidations => {
        fields.filter(f => f.field.dependencies && fieldId in f.field.dependencies)
            .forEach(d => {
                if(d.field.dependencies && !(Object.values(d.field.dependencies).flat().includes(field.value))) {
                    delete validations[d.field.id];
                }
            });
        setValidationPerField({validations, field, fieldId});
        if(hasPrevValueFromAPI) {
            const isDependentFields = fields.filter(f => !!f.field.required && Object.entries(f.field.dependencies ?? {}).some(f => f[0] === fieldId && f[1]?.includes(field.value)));
            isDependentFields.forEach(f => {
                setValidationPerField({validations, field: {
                    value: f.field.value,
                    type: f.field.fieldType,
                    label: f.field.title,
                }, fieldId: f.id});
            });
        }
        return validations;
    };

export const mapFieldsForChange = (values: FormMaxLeaseLoanField[]) =>
    values?.reduce((acc, f) => ({ ...acc, [f.id]: f.field.value}), {}); // converting fields array to object
