/* eslint-disable max-len */
import React, { useState } from "react";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { FormWrapper } from "../01-sections/FormWrapper";
import { FormField } from "../../07-organisms/Form/Field";
import { FieldType } from "../../../../store/Init/Types";
import { hasValueValidation, RTE } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/07-organisms";
import { Heading, Headings, Hr } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/05-atoms";
import { Link, LinkTarget } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/05-atoms/Link";
import { FormGroup } from "../../07-organisms/Form/Group";
import { Themes, ThemeShadowSizes } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/theme";
import { CodeCopy } from "../../05-atoms/CodeCopy";
import { Tabs } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/09-views/00-blocks";


export const GeneralTab: React.FunctionComponent<{iframeContent: string; theme: Themes; calcType: string; channel: string; scriptsContent: string; getLink: string }> = (props) => (<>
    <Mary.base.Div className="scl-b-row">
        <Mary.base.Grid
            size={{ xs: 12, md: 12 }}
        ><FormGroup asSummary label="Link" title="Dit is de link die u kan gebruiken voor deze calculator, door deze te embedden als iframe of direct te gebruiken.">
                <Link link={{
                    href: props.getLink,
                    target: LinkTarget.BLANK,
                }}>
                    <b>{props.getLink}</b>
                </Link>
                <div style={{textAlign: "right", marginTop: "5px"}}>
                    <CodeCopy content={props.getLink} />
                </div>
            </FormGroup>
        </Mary.base.Grid>
    </Mary.base.Div>
    <Hr/>
    <Mary.base.Div className="scl-b-row">
        <Mary.base.Grid
            size={{ xs: 12, md: 12 }}
        ><FormGroup asSummary label="Iframe (voorbeeld)" title="Hier kunt u bekijken hoe de calculator eruit komt te zien.">
                <Mary.base.Div theme={{shadow: ThemeShadowSizes.BIG}}>
                    <div dangerouslySetInnerHTML={{ __html: props.iframeContent }} style={{backgroundColor: props.theme === Themes.DUTCHLEASE ? "#000" : undefined }} />
                </Mary.base.Div>
            </FormGroup>
        </Mary.base.Grid>
    </Mary.base.Div>
</>);

export const IframeTab: React.FunctionComponent<{iframeContent: string; theme: string; scriptsContent: string }> = (props) => (<>
    <Mary.base.Div className="scl-b-row">
        <Mary.base.Grid
            size={{ xs: 12, md: 12 }}
        ><FormGroup label="Iframe voor gebruik op uw website">
                <RTE>
             U kunt de volgende code van het iframe gebruiken voor gebruik op uw website.
                </RTE><br />
                <RTE style={{minHeight: "300px"}}raw content={props.iframeContent} />
                <div style={{textAlign: "right", marginTop: "5px"}}>
                    <CodeCopy content={props.iframeContent} />
                </div>
            </FormGroup>
        </Mary.base.Grid>
    </Mary.base.Div>

    <Mary.base.Div className="scl-b-row">
        <Mary.base.Grid
            size={{ xs: 12, md: 12 }}
        ><FormGroup label="Iframe event listener">
                <RTE>
             Aangezien de hoogte van het formulier kan varieren in hoogte op basis van o.a. het wel of niet tonen van velden in het formulier, raden wij aan om gebruik te maken van de event listener, met deze listener krijgt u updates over de huidige hoogte van de content van het iframe, deze kunt u dan gebruiken om de iframe hoogte aan te passen, waardoor er nooit een scrollbalk zichtbaar is.<br/>
                </RTE><br />
                <RTE style={{minHeight: "300px"}}raw content={props.scriptsContent}/>
                <div style={{textAlign: "right", marginTop: "5px"}}>
                    <CodeCopy content={props.scriptsContent} />
                </div>
            </FormGroup>
        </Mary.base.Grid>
    </Mary.base.Div></>);


export const Setup: React.FunctionComponent<{}> = ({}) => {
    const [store, setStore] = useState<{
        theme?: string;
        calcType?: "max-loan" | "max-lease";
        channel?: string;
    }>({});

    const updateValue = (key: string, value?: string) => {
        setStore({ ...store, [key]: value });
    };


    const getLink = () =>
        `${window.location.origin}/${store.theme ?? ""}/${store.calcType ?? ""}?channel=${encodeURIComponent(store.channel ?? "")}`;


    const iframeContent = () => `
<iframe
    id="claude-scalc-${store.calcType ?? ""}-form"
    src="${getLink()}"
    style="border: none"
    width="100%"
    height="500px"
/>
`;


    const scriptsContent = () =>`
<script type="text/javascript">
    window.addEventListener(
        "message",  function (ev) {
    if (ev.data.type === "claude:scalc:${store.calcType ?? ""}:height") {
        console.log("Claude has received the init call with the height", ev.data.data.height);
        const claudeForm = document.getElementById("claude-scalc-${store.calcType ?? ""}-form");
        claudeForm.setAttribute("height", ev.data.data.height + "px")
    }
    if (ev.data.type === "claude:scalc:${store.calcType ?? ""}:amount") {
            console.log("Claude has received the amount on change", ev.data.data.amount);
    }}, false);
</script>`;

    const iframeTab = {
        key: "iframe",
        title: "Implementeer iframe",
        description: "",
        component: IframeTab,
    };
    const generalTab = {
        key: "general",
        title: "Algemeen",
        description: "",
        component: GeneralTab,
    };

    return (
        <FormWrapper>
            <Mary.base.Container
                theme={{
                    shadow: Mary.theme.ThemeShadowSizes.SMALL,
                    palette: Mary.theme.ThemePalette.CONTRAST_PRIMARY,
                    padding: {
                        "": { y: 3 },
                        "sm": { y: 4 },
                    },
                }}
            >
                <div style={{ minHeight: "300px", position: "relative" }}>
                    <div style={{ marginBottom: "15px" }}>
                        <Mary.atoms.Heading
                            level={Mary.atoms.Headings.H1}
                            headingStyle={Mary.atoms.Headings.H3}
                        >
                            Standalone Calculator Setup Wizard
                        </Mary.atoms.Heading>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Mary.organisms.RTE content="Deze setup wizard is er om makkelijk een standalone calculator te configureren voor gebruik in uw website of journey. De standalone calculator is ontwikkeld door Team Claude van Volkswagen Pon Financial Services. Configureer hier uw calculator door het formulier in te vullen, zodat u informatie krijgt over hoe u de calculator in kan zetten." />
                    </div>

                    <Mary.organisms.Form>
                        <Mary.base.Div className="scl-b-row">
                            <Mary.base.Grid
                                size={{ xs: 12, md: 6 }}
                            >
                                <FormField
                                    label="Kies een calculator"
                                    title={"Dit zijn de calculatoren die wij aanbieden."}
                                    type={FieldType.SELECT}
                                    required
                                    placeholder={Mary.utils.getText("app.make-a-choice", "Make a choice")}
                                    unsorted
                                    value={store.calcType}
                                    options={[{
                                        label: "Bereken max. leenbedrag",
                                        value: "max-loan",
                                    },{
                                        label: "Bereken max. leasebedrag",
                                        value: "max-lease",
                                    }]
                                    }
                                    onChange={(value?: string | number) =>
                                        updateValue("calcType",value ? value?.toString() : undefined)
                                    }
                                    validationFunction={"calcType" in store ? (v) => hasValueValidation(v, "Calculator type opgegeven") : undefined}
                                />
                            </Mary.base.Grid>
                            {store.calcType &&
                            <Mary.base.Grid
                                size={{ xs: 12, md: 6 }}
                            >
                                <FormField
                                    label="Kies een thema"
                                    title={"Dit zijn de themas (labels/brands) die wij aanbieden."}
                                    type={FieldType.SELECT}
                                    required
                                    placeholder={Mary.utils.getText("app.make-a-choice", "Make a choice")}
                                    unsorted
                                    value={store.theme}
                                    options={Object.values(Mary.theme.Themes).filter(f => f !== "politie" && f !== "xleasy").map(v => ( {
                                        label: v,
                                        value: v,
                                    }
                                    ))}
                                    onChange={(value?: string | number) =>
                                        updateValue("theme",value ? value?.toString() : undefined)
                                    }
                                    validationFunction={"theme" in store ? (v) => hasValueValidation(v, "Thema opgegeven") : undefined}
                                />
                            </Mary.base.Grid>
                            }
                        </Mary.base.Div>

                        {store.calcType && store.theme &&
                        <Mary.base.Div className="scl-b-row">
                            <Mary.base.Grid
                                size={{ xs: 12, md: 12 }}
                            >
                                <FormField
                                    label="Kanaal"
                                    title={"U moet een kanaal opgeven, bijv. 'seat-dealer-pon', op deze manier weten wij waar de calculator voor gebruikt wordt."}
                                    required
                                    placeholder={Mary.utils.getText("app.make-a-choice", "Make a choice")}
                                    unsorted
                                    value={store.channel}
                                    onChange={(value?: string | number) =>
                                        updateValue("channel",value ? value?.toString() : undefined)
                                    }
                                    validationFunction={"channel" in store ? (v) => hasValueValidation(v, "Kanaal opgegeven") : undefined}
                                />
                            </Mary.base.Grid>
                        </Mary.base.Div>
                        }

                    </Mary.organisms.Form>
                    {store.calcType && store.theme && store.channel && (
                        <>
                            <Hr/>
                            <Heading level={Headings.H4}>Jouw configuratie</Heading><br/>
                            <Mary.base.Div className="scl-b-row">
                                <Mary.base.Grid
                                    size={{ xs: 12, md: 3 }}
                                >

                                    <FormField
                                        asSummary
                                        label="Calculator type"
                                        value={store.calcType} />

                                </Mary.base.Grid>
                                <Mary.base.Grid
                                    size={{ xs: 12, md: 6 }}
                                >
                                    <FormGroup asSummary label="Thema">

                                        <Mary.base.Logo
                                            variant={store.theme === Themes.DUTCHLEASE ? Mary.theme.ThemeVariants.CONTRAST : Mary.theme.ThemeVariants.BRAND}
                                            theme={store.theme as Themes}
                                            style={{ maxHeight: "22px", maxWidth: "250px", margin: "0 auto" }}
                                        />
                                    </FormGroup>
                                </Mary.base.Grid>
                                <Mary.base.Grid
                                    size={{ xs: 12, md: 12 }}
                                >
                                    <FormField
                                        asSummary
                                        label="Kanaal"
                                        value={store.channel}

                                    />
                                </Mary.base.Grid>
                            </Mary.base.Div>
                            <Tabs tabs={[generalTab, iframeTab]} defaultTab={generalTab} params={{iframeContent: iframeContent(), ...store, scriptsContent: scriptsContent(), getLink: getLink() }}/>
                        </>

                    )}
                </div>
            </Mary.base.Container>
        </FormWrapper>
    );

};
