import "core-js";

import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
    BrowserRouter as Router, Route, RouteComponentProps, Switch,
} from "react-router-dom";

import { I18n } from "@aws-amplify/core";
import { App } from "./App";
import { unregister } from "./serviceWorker";
import { createReduxStore } from "./store/ReduxStore";
import { VerifyContextWrapper } from "./App/utils/VerifyContextWrapper";
import { I18nAppTranslationsNL } from "./App/utils/translations/nl/I18n";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

I18n.configure(Mary.utils.I18nMarylocales);
I18n.putVocabularies({
    nl: {
        ...Mary.utils.I18nMaryTranslations.nl,
        ...I18nAppTranslationsNL,
    },
});


const Root: React.FunctionComponent<{}> = ({}) => {
    const store = createReduxStore({
        apiEndpoint: process.env.REACT_APP_API_ENDPOINT || "",
    });
    const inIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    };
    return (
        <Mary.base.BaseProvider
            theme={Mary.theme.Themes.CLAUDE}
            dynamic
            language={Mary.utils.I18nLanguage.DUTCH}
        >
            <Mary.utils.ErrorBoundary>
                <Provider store={store}>
                    <Router>
                        <Mary.utils.MaryProvider flexDefaultFocus={Mary.base.FlexWrapper.FlexWrapperFocus.CONTENT}
                            asWidget={inIframe()}>
                            <VerifyContextWrapper>
                                <Mary.base.BaseConsumer>{({ setTheme }) =>
                                    (
                                        <Switch>
                                            <Route
                                                render={(p: RouteComponentProps ) => (
                                                    <App {...p} setTheme={setTheme} />
                                                )}
                                            />
                                        </Switch>
                                    )}
                                </Mary.base.BaseConsumer>
                            </VerifyContextWrapper>
                            <Mary.base.PreLoader />
                        </Mary.utils.MaryProvider>
                    </Router>
                </Provider>
            </Mary.utils.ErrorBoundary>
        </Mary.base.BaseProvider>);
};

const elm = document.getElementById("root") as HTMLElement;
if (elm) {
    ReactDOM.render(
        <Root />,
        elm
    );
}

unregister();
